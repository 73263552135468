const BucketURL = 'https://tfsbankingwebsitestorage.blob.core.windows.net/tfs-banking-site-fonts/';

export const MontserratBlack = `${BucketURL}Montserrat-Black.ttf`;
export const MontserratBlackItalic = `${BucketURL}Montserrat-BlackItalic.ttf`;
export const MontserratBold = `${BucketURL}Montserrat-Bold.ttf`;
export const MontserratBoldItalic = `${BucketURL}Montserrat-BoldItalic.ttf`;
export const MontserratExtraBold = `${BucketURL}Montserrat-ExtraBold.ttf`;
export const MontserratExtraBoldItalic = `${BucketURL}Montserrat-ExtraBoldItalic.ttf`;
export const MontserratExtraLight = `${BucketURL}Montserrat-ExtraLight.ttf`;
export const MontserratExtraLightItalic = `${BucketURL}Montserrat-ExtraLightItalic.ttf`;
export const MontserratItalic = `${BucketURL}Montserrat-Italic.ttf`;
export const MontserratLight = `${BucketURL}Montserrat-Light.ttf`;
export const MontserratLightItalic = `${BucketURL}Montserrat-LightItalic.ttf`;
export const MontserratMedium = `${BucketURL}Montserrat-Medium.ttf`;
export const MontserratMediumItalic = `${BucketURL}Montserrat-MediumItalic.ttf`;
export const MontserratRegular = `${BucketURL}Montserrat-Regular.ttf`;
export const MontserratSemiBold = `${BucketURL}Montserrat-SemiBold.ttf`;
export const MontserratSemiBoldItalic = `${BucketURL}Montserrat-SemiBoldItalic.ttf`;
export const MontserratThin = `${BucketURL}Montserrat-Thin.ttf`;
export const MontserratThinItalic = `${BucketURL}Montserrat-ThinItalic.ttf`;
export const DancingScript = `${BucketURL}DancingScript/DancingScript-Regular.ttf`;
export const DancingScriptBold = `${BucketURL}DancingScript/DancingScript-Bold.ttf`;
