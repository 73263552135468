import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const BannerOption = ({ link, label, isCardActivation = false }) => {
  const swalPopUp = withReactContent(Swal);
  const navigate = useNavigate();

  const onCardActivateClick = useCallback(
    (event) => {
      event.preventDefault();
      swalPopUp
        .fire({
          title: 'Activate Card',
          html: `
            <div class="flex flex-col justify-start items-start gap-4">
              <p>Do not activate your card until you have your Physical Card in hand.</p>
              <p>Do you have your Physical card and want to activate it?</p>
            </div>
            `,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        })
        .then((result) => {
          if (result.isConfirmed) {
            navigate(link, { replace: true });
          }
        });
    },
    [navigate]
  );

  return (
    <li className="flex justify-start items-center gap-6 bg-white rounded-xl px-5 py-3">
      <i className="fa-light fa-credit-card fa-lg text-greyblue" />
      {isCardActivation ? (
        <a id="activateCard" onClick={(event) => onCardActivateClick(event)}>
          <div className="flex flex-col justify-start items-start cursor-pointer">
            <span className="text-link font-semibold">{label}</span>{' '}
            <div>
              <span className="text-teal font-medium underline">Here</span>{' '}
              <i className="fa-regular fa-chevron-right text-seafoam" />
            </div>
          </div>
        </a>
      ) : (
        <Link to={link}>
          <div className="flex flex-col justify-start items-start">
            <span className="text-link font-semibold">{label}</span>{' '}
            <div>
              <span className="text-teal font-medium underline">Here</span>{' '}
              <i className="fa-regular fa-chevron-right text-seafoam" />
            </div>
          </div>
        </Link>
      )}
    </li>
  );
};

export default BannerOption;
