const BucketURL = 'https://tfsbankingwebsitestorage.blob.core.windows.net/tfs-banking-site-images/';

export const TFSLogoBorderImg = `${BucketURL}TFS_LogoBorder.jpg`;
export const TFSWhiteImg = `${BucketURL}TFS_White.png`;
export const TFSLogoImg = `${BucketURL}TFS_logo.png`;
export const AchHelpImg = `${BucketURL}ach_help.png`;
export const AlertIconImg = `${BucketURL}alert-icon.png`;
export const Banner1Img = `${BucketURL}banner_1.png`;
export const Banner2Img = `${BucketURL}banner_2.png`;
export const Banner3Img = `${BucketURL}banner_3.png`;
export const BannerIncompleteImg = `${BucketURL}banner_incomplete.png`;
export const CheckImg = `${BucketURL}check.png`;
export const CircleCheckImg = `${BucketURL}circle_check.png`;
export const CreditCardImg = `${BucketURL}credit_card.png`;
export const FacebookImg = `${BucketURL}facebook.png`;
export const FaviconIco = `${BucketURL}favicon.ico`;
export const FraudCardImg = `${BucketURL}fraud_card.png`;
export const FundingBackgroundImg = `${BucketURL}funding_background.png`;
export const InstagramImg = `${BucketURL}instagram.png`;
export const LinkedinImg = `${BucketURL}linkedin.png`;
export const NegativeBalanceImg = `${BucketURL}negative_balance.png`;
export const SignUpImg = `${BucketURL}sign_up.png`;
export const TrackerAvatarImg = `${BucketURL}dollar_sign_avatar.png`
export const TwitterImg = `${BucketURL}twitter.png`;
export const VoidImg = `${BucketURL}void.png`;
export const SuccessTrackerImg = `${BucketURL}tracker_advert.png`;
export const SuccessTrackerBgImg = `${BucketURL}tracker_background.png`;
export const TFSRewardsLearnMoreImg = `${BucketURL}cash_back.jpg`;
export const PaymentAssistantLearnMoreImg = `${BucketURL}bill_tracking.jpg`;
export const TrackerLearnMoreImg = `${BucketURL}tracker_demo.png`;
export const YourSuccessLogoImg = `${BucketURL}your_success_logo.png`;
export const YourSuccessLogoWhiteImg = `${BucketURL}your_success_logo_white.png`;
export const YourSuccessCardImg = `${BucketURL}your_success_card.png`;
export const BannerPersonImg = `${BucketURL}banner_person.png`;
export const ManLookingAtPhoneImg = `${BucketURL}man_looking_at_phone.png`;
export const PointingWomanWithPhoneImg = `${BucketURL}pointing_woman_w_phone.png`;
export const CoupleInKitchenImg = `${BucketURL}couple_in_kitchen.jpg`;
export const MastercardBlackImg = `${BucketURL}mastercard_black.png`;
export const WorkingAtDeskImg = `${BucketURL}working_at_desk.jpg`;
export const GraphImg = `${BucketURL}graph.png`;
export const WomenShoppingImg = `${BucketURL}women_shopping.jpg`;
export const CardAndAppImg = `${BucketURL}card-and-app.png`;
export const DebitExplainCollageImg = `${BucketURL}collage.png`;
export const SuccessTrackerInfoImg = `${BucketURL}success_tracker_background.jpg`;
